import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import Review from "../components/review";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const ReviewsPage = () => {
	const reviewsData = useStaticQuery(
		graphql`
			query {
				heroImg: file(relativePath: { eq: "home-hero-2.jpg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData(
							quality: 100
							placeholder: BLURRED
							formats: [AUTO, WEBP]
						)
					}
				}
				allWpReviews {
					nodes {
						id
						reviewfields {
							author
							company
							quote
							profilePic {
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100

											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		`
	);

	return (
		<Layout>
			<GatsbySeo
				title="Our commerical photography reviews | Sarah Bennett Commercial Photography"
				description="Read our commerical photography service reviews from business owners across East and West Sussex"
				language="en"
				canonical="https://www.sarah-bennett.com/reviews"
				openGraph={{
					url: "https://www.sarah-bennett.com/reviews",
					title:
						"Our commericial photography reviews | Sarah Bennett Commercial Photography",
					description:
						"Read our commerical photography service reviews from business owners across East and West Sussex",
					images: [
						{
							url: `${reviewsData.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Our commerical photography reviews",
						},
					],
				}}
			/>
			<section className="pt-6">
				<Container>
					<Row>
						<Col>
							<h1 className="text-center">
								Our Commercial Photography Testimonials
							</h1>
							<hr className="w-25 mx-auto" />
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-6">
				<Container>
					<Row className="text-center">
						<Col>
							<iframe
								width="100%"
								height="500"
								src="https://www.youtube.com/embed/eJGSvjmebiY"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</Col>
					</Row>
					<Row>
						{reviewsData.allWpReviews?.nodes.map((review) => (
							<Col key={review.id} xs={12}>
								<Review
									quote={review.reviewfields.quote}
									company={review.reviewfields.company}
									author={review.reviewfields.author}
									img={
										review.reviewfields.profilePic?.localFile.childImageSharp
											.gatsbyImageData
									}
								/>
							</Col>
						))}
					</Row>
				</Container>
			</section>
			{/* <CtaBanner/>             */}
		</Layout>
	);
};

export default ReviewsPage;
